<template>
  <div class="diagnostics">
    <v-col cols="12" sm-11>
      <v-card>
        <v-card-title>
          <h3>Diagnostic Trouble Codes</h3>&nbsp;
          (<a href="#" v-on:click.prevent="downloadCSVData"> Download CSV </a>)
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-card-subtitle>
          Firmware: {{ dtclist_firmware_version }}
        </v-card-subtitle>
        <v-data-table :headers="headers" :items="dtc_list" :search="search" :footer-props="{'items-per-page-options':[30, 50, 100, -1]}" :items-per-page="30" dense>
          <!--template v-slot:item.type="{ item }">
              <v-chip :color="levelColor(item)"> {{ item.type }}</v-chip>
          </template-->
          <!--template #item.titlelink="{ item }">
                <a :href="`dtc/${item.code}`" >{{ item.title }}</a>
          </template-->
          <template #item.code="{ value }">
                <a :href="`dtc/${value}`" >{{ value }}</a>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
  export default {
    name: 'AllDTCsTable',
    data () {
      return {
        search: '',
        headers: [
          { text: 'ID', value: 'id', filterable: false },
          /*{ text: 'Type', filterable: false, value: 'type' },*/
          { text: 'Label', value: 'label', filterable: true, sortable: false },
          { text: 'Scope', value: 'scope', filterable: false, sortable: false },
          { text: 'Code', value: 'code', filterable: true },// This is shortlabel
        ],
        dtc_list: [],
        dtclist_firmware_version: "0.00.00000"
      }
    },
    methods: {
      downloadCSVData() {
          let csv = 'Code,Scope,Label,\n';
          this.dtc_list.forEach((obj) => {
            csv += obj["code"] + "," + obj["scope"] + "," + obj["label"];
            csv += ",\r\n";
          });
      
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = 'dtclist.csv';
          anchor.click();
      },
      levelColor: function (rowobject) {
        var severity = "";
        switch(rowobject.type) {
          case 1:
            severity = colors.blue.lighten4;
            break;
          case 2:
            severity = "yellow";
            break;
          case 3:
            severity = "red";
            break;
          default:
            severity = "white";
            break;
            // code block
        }
        return severity;
      }
    },
    mounted(){
      //this.$emit('loadstart');
      this.$api.get(this.$dtcdburl + "/")
      .then(response => {
        if (response.data["code"] == "get_all_dtcs") {
          this.dtclist_firmware_version = response.data["fwver"];
          this.dtc_list = response.data["dtclist"];
          //console.log(this.dtc_list);
          // Set the initial number of items
          //this.totalRows = this.bill_list.length;
          //this.$emit('loadcomplete');
        }
      });
    },
  }
</script>
<style scoped>
td.text-start > tr {
  padding: 10px;
  margin: 100px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
td > a {
  height: 60px;  
}
</style>